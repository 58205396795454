
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.placeholder-card {
    position: relative;
    overflow: hidden;
    background: rgba(85, 85, 85, 0.8);

    .background-image {
        filter: blur(4px);
        opacity: 0.5;
    }

    .play-button-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100%;
        color: $main-background;
        font-weight: light;

        .play-icon {
            color: $main-background;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }

        .play-overlay-text {
            position: absolute;
            font-size: 0.85em;
            top: 55%;
            left: 50%;
            max-width: 50%;
            transform: translate(-50%, 0%);
        }
    }
}
