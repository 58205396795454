
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.video-placeholder {
    width: 100%;
    height: 300px;
    background: rgba(0, 0, 0, 0.05);
}
